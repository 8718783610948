import TopMenu from "./components/TopMenu"
import Header from "./components/Header"
import Hero from "./components/Hero"
import Servicios from "./components/Servicios"
import Footer from "./components/Footer"
import LoginSection from "./components/Login/LoginSectionV2"

function App() {
  return (
    <>
      <LoginSection />
      {/* <div id="qodef-page-wrapper">
        <TopMenu />
        <Header />
        <div id="qodef-page-outer">
          <Hero />
          <Servicios />
        </div>
        <Footer />
        <div className="qodef-fullscreen-search-holder qodef-m">
          <a href="javascript:void(0)" className="qodef-opener-icon qodef-m qodef-source--icon-pack qodef-m-close">
            <span className="qodef-m-icon qodef--open">
              <span className="qodef-icon-linea-icons icon-arrows-remove" ></span>
            </span>
          </a>
          <div className="qodef-m-inner">
            <form action="https://hero.boston/" className="qodef-m-form" method="get">
              <input type="text" placeholder="Search here..." name="s" className="qodef-m-form-field" autocomplete="off" required />
              <div className="qodef-m-form-line"></div>
            </form>
          </div>
        </div>
        <a id="qodef-back-to-top" href="#">
          <span className="qodef-back-to-top-icon">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="13" x="0px" y="0px" viewBox="0 0 22 15" style={{ enableBackground: "new 0 0 22 15" }} xmlSpace="preserve">
              <g>
                <line className="st0" x1="21" y1="7.6" x2="1" y2="7.6"/>
                <polyline className="st0" points="14.2,0.8 20.9,7.6 14.1,14.4"/>
              </g>
            </svg>
          </span>
        </a>
        <div id="qodef-side-area">
          <a href="javascript:void(0)" id="qodef-side-area-close" className="qodef-opener-icon qodef-m qodef-source--predefined">
            <span className="qodef-m-icon qodef--open">
              <span className="qodef-m-lines">
              <span class="qodef-m-line qodef--1"></span>
              <span class="qodef-m-line qodef--2"></span>
              <span class="qodef-m-line qodef--3"></span>
              <span class="qodef-m-line qodef--4"></span>
              <span class="qodef-m-line qodef--5"></span>
              <span class="qodef-m-line qodef--6"></span>
              <span class="qodef-m-line qodef--7"></span>
              <span class="qodef-m-line qodef--8"></span>
              <span class="qodef-m-line qodef--9"></span>
              </span>
            </span>
          </a>
          <div id="qodef-side-area-inner">
            <div id="text-5" className="widget widget_text" data-area="side-area">
              <div className="textwidget">
                <p><img decoding="async" className="alignnone wp-image-8681" src="https://expertsign.tech/wp-content/uploads/2021/10/expertsign.png" alt="f" width="180" /></p>
              </div>
            </div>
            <div id="primeinvest_core_separator-18" className="widget widget_primeinvest_core_separator" data-area="side-area">
              <div className="qodef-shortcode qodef-m  qodef-separator clear ">
                <div className="qodef-m-line" style={{ width: "1px", borderBottomWidth: "1px", marginTop: "9px", marginBottom: "0px" }}></div>
              </div>
            </div>
            <div id="custom_html-10" className="widget_text widget widget_custom_html" data-area="side-area">
              <div className="textwidget custom-html-widget">
                <h4 className="qodef-shortcode qodef-m  qodef-custom-font qodef-custom-font-3137 qodef-layout--simple" style={{ fontFamily: "Rubik", marginBottom: "20px" }}>About us</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod. Tempor inciditnt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
            <div id="custom_html-9" className="widget_text widget widget_custom_html" data-area="side-area">
              <div className="textwidget custom-html-widget">
                <p className="qodef-e-title" style={{ margin: "-16px 0 0" }}>
                  <span className="qodef-e-title-inner">
                    <span className="qodef-shortcode qodef-m  qodef-icon-holder qodef-size--default qodef-layout--normal" style={{ marginRight: "8px"}}>
                      <span className="qodef-icon-ionicons ion-ios-send qodef-icon qodef-e" style={{ color: "#237a40", fontSize: "25px" }}></span>
                    </span>
                    <span className="qodef-e-title-text">
                      <a href="mailto:primeinvest@qodeinteractive.com">prime@qodeinteractive.com</a>
                    </span>
                  </span>
                </p>
                <p className="qodef-e-title" style={{ margin: "3px 0 0" }}>
                  <span className="qodef-e-title-inner">
                    <span className="qodef-shortcode qodef-m  qodef-icon-holder qodef-size--default qodef-layout--normal" style={{ marginRight: "8px" }}>
                      <span className="qodef-icon-ionicons ion-ios-call qodef-icon qodef-e" style={{ color: "#237a40", fontSize: "25px" }}></span>
                      <span className="qodef-e-title-text"><a href="tel:+381123456677">+381 123 456 677</a></span>
                    </span>
                  </span>
                </p>
                <p className="qodef-e-title" style={{ margin: "25px 0 0" }}>
                  <span className="qodef-e-title-inner">
                    <span className="qodef-shortcode qodef-m  qodef-icon-holder qodef-size--default qodef-layout--normal" style={{ marginRight: "8px" }}>
                      <span className="qodef-icon-ionicons ion-md-time qodef-icon qodef-e" style={{ color: "#237a40", fontSize: "20px" }}></span>
                    </span>
                    <span className="qodef-e-title-text">Mon - Sat 8.00 - 18.00 </span>
                  </span>
                </p>
                <p className="qodef-shortcode qodef-m  qodef-custom-font qodef-custom-font-4772 qodef-layout--simple" style={{ color: "#9c9c9c", margin: "0 0 0 29px" }}>Sunday closed</p>
              </div>
            </div>
          </div>
          <div id="qodef-side-area-bottom">
            <div id="primeinvest_core_social_icons_group-3" className="widget widget_primeinvest_core_social_icons_group" data-area="side-area-bottom">
              <div className="qodef-social-icons-group">
                <span className="qodef-shortcode qodef-m  qodef-icon-holder  qodef-layout--square" data-hover-background-color="#237a40" style={{ backgroundColor: "#8fd26c" }}>
                  <a itemprop="url" href="https://twitter.com/QodeInteractive" target="_blank">
                    <span className="qodef-icon-ionicons ion-logo-twitter qodef-icon qodef-e" style={{ color: "#ffffff" }}></span>
                  </a>
                </span>
                <span className="qodef-shortcode qodef-m  qodef-icon-holder  qodef-layout--square" data-hover-background-color="#237a40" style={{ backgroundColor: "#8fd26c" }}>
                  <a itemprop="url" href="https://www.instagram.com/qodeinteractive/" target="_blank">
                    <span className="qodef-icon-ionicons ion-logo-instagram qodef-icon qodef-e" style={{ color: "#ffffff" }}></span>
                  </a>
                </span>
                <span className="qodef-shortcode qodef-m  qodef-icon-holder  qodef-layout--square" data-hover-background-color="#237a40" style={{ backgroundColor: "#8fd26c" }}>
                  <a itemprop="url" href="https://www.linkedin.com/company/qode-themes/" target="_blank">
                    <span className="qodef-icon-ionicons ion-logo-linkedin qodef-icon qodef-e" style={{ color: "#ffffff" }}></span>
                  </a>
                </span>
                <span className="qodef-shortcode qodef-m  qodef-icon-holder  qodef-layout--square" data-hover-background-color="#237a40" style={{ backgroundColor: "#8fd26c" }}>
                  <a itemprop="url" href="https://www.facebook.com/QodeInteractive/" target="_blank">
                    <span className="qodef-icon-ionicons ion-logo-facebook qodef-icon qodef-e" style={{ color: "#ffffff" }}></span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default App
