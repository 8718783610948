import GoogleButton from "../buttons/GoogleButton"
import FacebookButton from "../buttons/FacebookButton"
import MicrosoftButton from "../buttons/MicrosoftButton"
import SSOButton from "../buttons/SSOButton"
import AmazonButton from "../buttons/AmazonButton"
import InputField from "./InputField"

import { Formik, Form } from "formik"
// import { login } from "../../utils/cognito-funtions"

export default function LoginForm({ handleLogin }) {

    const initialValues = {
        username: '',
        password: '',
    }

    const validate = values => {
        const errors = {}

        if( !values.username ) {
            errors.username = 'Requerido'
        } else if( !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.username) ) {
            errors.username = 'Correo no Valido'
        }

        if( !values.password ) {
            errors.password = 'Requerido'
        }

        return errors;
    }

    // const handleSubmit = async values => {
    //     console.log(values)
    //     const response = await login(values.username, values.password)
    //     console.log(response)
    // }

    return (
        <section className="elementor-section elementor-inner-section elementor-element elementor-element-6a1701d elementor-section-boxed elementor-section-height-default elementor-section-height-default qodef-elementor-content-no" data-id="6a1701d" data-element_type="section">
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleLogin}>
                <Form>
                    <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-3f744d3" data-id="3f744d3" data-element_type="column">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-9e5ef36 elementor-widget elementor-widget-html" data-id="9e5ef36" data-element_type="widget" data-widget_type="html.default">
                                    <div className="elementor-widget-container">
                                        <GoogleButton />
                                    </div>
                                </div>
                                <div className="elementor-element elementor-element-66651e0 elementor-widget elementor-widget-html" data-id="66651e0" data-element_type="widget" data-widget_type="html.default">
                                    <div className="elementor-widget-container">
                                        <FacebookButton />
                                    </div>
                                </div>
                                <div className="elementor-element elementor-element-740146c elementor-widget elementor-widget-html" data-id="740146c" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <MicrosoftButton />
                                    </div>
                                </div>
                                <div className="elementor-element elementor-element-d81d3ea elementor-widget elementor-widget-html" data-id="d81d3ea" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <SSOButton />
                                    </div>
                                </div>
                                <div className="elementor-element elementor-element-d81d3ea elementor-widget elementor-widget-html" data-id="d81d3ea" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <AmazonButton />
                                    </div>
                                </div>
                                <div className="divider">
                                    <span>Or</span>
                                </div>
                                <div className="elementor-element elementor-element-3a0de67 elementor-widget__width-inherit elementor-widget elementor-widget-html" data-id="3a0de67" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;none&quot;}" data-widget_type="html.default">
                                    <InputField type="email" id="id-username" name="username" placeholder="Email" label="Email" />
                                </div>
                                <div className="elementor-element elementor-element-69acec4 elementor-widget__width-inherit elementor-widget elementor-widget-html" data-id="69acec4" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;none&quot;}" data-widget_type="html.default">
                                    <InputField type="password" id="id-password" name="password" placeholder="Contraseña" label="Contraseña" />
                                </div>
                                <button
                                    type="submit"
                                    style={{
                                        width: '100%',
                                        padding: '9px 10px',
                                        border: '1px solid #7c8084',
                                        borderRadius: '10px',
                                        backgroundColor: '#F4F5F5',
                                        color: '#7A7A7A',
                                        fontFamily: 'Roboto, sans-serif',
                                        fontWeight: 500,
                                        fontSize: '13px'
                                    }}
                                >Iniciar Sesión</button>
                                {/* <div className="elementor-element elementor-element-cb45dbb elementor-align-center elementor-widget elementor-widget-button" data-id="cb45dbb" data-element_type="widget" data-widget_type="button.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                            <a href="#" className="elementor-button-link elementor-button elementor-size-xs" role="button">
                                                <span className="elementor-button-content-wrapper">
                                                    <span className="elementor-button-text">Continue</span>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </section>
    )
}