export default function SSOButton() {
    return (
        <button
            type="button"
            className="btn"
            style={{
                backgroundColor: 'var(--zds-colors-neutral-100, #fffdf9)',
                color: 'var(--zds-colors-blue-jeans, #3d4592)'
            }}
        >
            <div className="content">
                <div className="icon">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="var(--zds-colors-blue-jeans, #3d4592)">
                        <title>Continue with SSO logo</title>
                        <path d="M10.7715 0.184814C5.26148 0.184814 0.771484 4.67481 0.771484 10.1848C0.771484 13.6648 2.56148 16.7248 5.26148 18.5248L6.47148 15.1848H15.0715L15.5715 16.5648C14.2315 17.5748 12.5715 18.1848 10.7715 18.1848C9.69148 18.1848 8.67148 17.9648 7.73148 17.5848L7.05148 19.4648C8.20148 19.9248 9.45148 20.1848 10.7715 20.1848C16.2815 20.1848 20.7715 15.6948 20.7715 10.1848C20.7715 4.67481 16.2815 0.184814 10.7715 0.184814ZM17.1315 15.0148L16.4715 13.1848H5.07148L4.41148 15.0148C3.38148 13.6648 2.77148 11.9948 2.77148 10.1848C2.77148 5.77481 6.36148 2.18481 10.7715 2.18481C15.1815 2.18481 18.7715 5.77481 18.7715 10.1848C18.7715 11.9948 18.1615 13.6648 17.1315 15.0148Z" fill="#3D4592"></path><path d="M10.7615 4.22485C8.70148 4.22485 7.02148 5.90485 7.02148 7.96485C7.02148 10.0249 8.70148 11.7049 10.7615 11.7049C12.8215 11.7049 14.5015 10.0249 14.5015 7.96485C14.5015 5.90485 12.8215 4.22485 10.7615 4.22485ZM10.7615 9.70485C9.80148 9.70485 9.02148 8.92485 9.02148 7.96485C9.02148 7.00485 9.80148 6.22485 10.7615 6.22485C11.7215 6.22485 12.5015 7.00485 12.5015 7.96485C12.5015 8.92485 11.7215 9.70485 10.7615 9.70485Z" fill="#3D4592"></path>
                    </svg>
                </div>
            </div>
            Continue with SSO
        </button>
    )
}