import { Formik, Form } from "formik"
import InputField from "./InputField"
import { changePassword } from "../../utils/cognito-funtions"

export default function ChangePasswordForm({ challengeData }) {

    // const data = JSON.parse(challengeData.ChallengeParameters.requiredAttributes)
    // const newData = data.map( e => e.slice(15) )

    // console.log(data)
    // console.log(newData)

    const initialValues = {
        password1: '',
        password2: '',
    }

    const validate = values => {
        const errors = {}

        if( !values.password1 ) {
            errors.password1 = "Requerido"
        } else if( /^\s/.test(values.password1) || /\s$/.test(values.password1) ) {
            errors.password1 = "La contraseña no debe contener un espacio inicial o final"
        } else if( !/[a-z]/.test(values.password1) ) {
            errors.password1 = "La contraseña debe contener una letra minúscula"
        } else if( !/[A-Z]/.test(values.password1) ) {
            errors.password1 = "La contraseña debe contener una letra mayúscula"
        } else if( !/\d/.test(values.password1) ) {
            errors.password1 = "La contraseña debe contener un número"
        } else if( !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(values.password1) ) {
            errors.password1 = "La contraseña debe contener un carácter especial"
        } else if( values.password1.length < 8 ) {
            errors.password1 = "La contraseña debe contener al menos 8 caracteres"
        }

        if( !values.password2 ) {
            errors.password2 = "Requerido"
        } else if( values.password1 != values.password2 ) {
            errors.password1 = "Las contraseñas deben coincidir"
            errors.password2 = "Las contraseñas deben coincidir"
        }

        return errors
    }

    const handleSubmit = async values => {
        // console.log(values)
        // console.log(challengeData)
        // console.log({ username: challengeData.ChallengeParameters.USER_ID_FOR_SRP, password: values.password1, session: challengeData.Session })

        const response = await changePassword(challengeData.ChallengeParameters.USER_ID_FOR_SRP, values.password1, challengeData.Session)
        console.log(response)
    }

    return (
        <section className="elementor-section elementor-inner-section elementor-element elementor-element-6a1701d elementor-section-boxed elementor-section-height-default elementor-section-height-default qodef-elementor-content-no" data-id="6a1701d" data-element_type="section">
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
                <Form>
                    <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-3f744d3" data-id="3f744d3" data-element_type="column">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-3a0de67 elementor-widget__width-inherit elementor-widget elementor-widget-html" data-id="3a0de67" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;none&quot;}" data-widget_type="html.default">
                                    <InputField type="password" id="id-password1" name="password1" placeholder="Contraseña" label="Contraseña" />
                                </div>
                                <div className="elementor-element elementor-element-69acec4 elementor-widget__width-inherit elementor-widget elementor-widget-html" data-id="69acec4" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;none&quot;}" data-widget_type="html.default">
                                    <InputField type="password" id="id-password2" name="password2" placeholder="Confirmar Contraseña" label="Confirmar Contraseña" />
                                </div>
                                <button
                                    type="submit"
                                    style={{
                                        width: '100%',
                                        padding: '9px 10px',
                                        border: '1px solid #7c8084',
                                        borderRadius: '10px',
                                        backgroundColor: '#F4F5F5',
                                        color: '#7A7A7A',
                                        fontFamily: 'Roboto, sans-serif',
                                        fontWeight: 500,
                                        fontSize: '13px'
                                    }}
                                >Cambiar Contraseña</button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </section>
    )
}