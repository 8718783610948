import { useField } from "formik"

export default function InputField({ label, ...props }) {
    const [field, meta] = useField(props.name)

    return (
        <div className="elementor-widget-container">
            <div aria-hidden="false" data-testid="emailField-wrapper" style={{ marginBottom: "20px" }}>
                <div style={{ display: "grid", gap: "5px" }}>
                    <div
                        style={{
                            all: 'unset',
                            boxSizing: 'border-box',
                            WebkitTextFillColor: 'currentcolor',
                            display: 'block'
                        }}
                    >
                        <label data-align-items="start" data-justify-content="start" data-size="small"
                            htmlFor={props.id}
                            style={{
                                fontFamily: 'var(--zds-typography-base, "Inter", Helvetica, arial, sans-serif)',
                                fontSize: '14px',
                                lineHeight: '20px',
                                fontWeight: 600
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: 'var(--zds-typography-base, "Inter", Helvetica, arial, sans-serif)',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    fontWeight: 600
                                }}
                            >{label}</span>
                            <span
                                aria-hidden="true"
                                style={{
                                    fontFamily: 'var(--zds-typography-base, "Inter", Helvetica, arial, sans-serif)',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    fontWeight: 400,
                                    color: 'var(--zds-colors-neutral-700,#403f3e)',
                                    textTransform: 'lowercase'
                                }}
                            >(Requerido)</span>
                        </label>
                    </div>
                    <div
                        style={{
                            all: 'unset',
                            boxSizing: 'border-box',
                            WebkitTextFillColor: 'currentcolor',
                            display: 'block'
                        }}
                    >
                        <div data-layout="inline" data-size="small" role="presentation" className="--Input-borderRadius-50b78b2a: 3px;--Input-iconColor-3a9a8562: var(--zds-colors-neutral-600,#95928e);--Input-inputHeight-365b533b: 42px;--Input-inputPaddingLeft-581056da: 15px;--Input-inputPaddingRight-55af1a57: 15px;--Input-inputPaddingTop-79079bde: 9px;--Input-inputPaddingBottom-7b2717c2: 9px;--Input-placeholderColor-7fa032fc: var(--zds-colors-neutral-600,#95928e);--Input-inputValueColor-628f6f0: var(--zds-colors-neutral-800,#2d2e2e);--Input-prependPaddingLeft-3b1cd26a: 15px;--Input-appendPaddingRight-3c8bab11: 15px;--Input-outlineColor-587a6073: var(--zds-colors-neutral-600,#95928e);--Input-prependAppendColor-539be1b9: var(--zds-colors-neutral-800,#2d2e2e);--Input-tooltipOpacity-14c432d4: 0;border: 1px solid var(--Input-outlineColor-587a6073);box-shadow: none;position: relative;border-radius: var(--Input-borderRadius-50b78b2a);background-color: var(--zds-colors-neutral-100,#fffdf9);transition-duration: 300ms;transition-property: background-color, color, box-shadow;transition-timing-function: ease;">
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    WebkitBoxAlign: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <input aria-invalid="false" data-size="small" autocomplete="email"
                                    {...field}
                                    {...props}
                                    style={{
                                        flex: '1 1 0%',
                                        fontFamily: 'var(--zds-typography-base, "Inter", Helvetica, arial, sans-serif)',
                                        fontSize: '16px',
                                        lineHeight: 'var(--zds-typography-paragraph3-lineheight, 25px)',
                                        fontWeight: 500,
                                        color: 'var(--Input-inputValueColor-628f6f0)',
                                        height: '100%',
                                        width: '100%',
                                        paddingTop: 'var(--Input-inputPaddingTop-79079bde)',
                                        paddingBottom: 'var(--Input-inputPaddingBottom-7b2717c2)',
                                        paddingLeft: 'var(--Input-inputPaddingLeft-581056da)',
                                        paddingRight: 'var(--Input-inputPaddingRight-55af1a57)'
                                    }}
                                />
                            </div>
                            { meta.touched && meta.error ? <p style={{ color: 'red', margin: 0 }}>{meta.error}</p> : null }
                        </div>
                    </div>
                    <div id="login-email-description" class="css-puqizh-Field__helpTextWrapper"></div>
                </div>
            </div>
        </div>
    )
}