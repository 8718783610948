import { CognitoIdentityServiceProvider, config } from "aws-sdk"
import generateSecretHash from "./generate-secret-hash"

// AWS_ACCESS_KEY=AKIATCKASUEOFDVTKZ33
// AWS_SECRET_KEY=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
// AWS_REGION_NAME=us-east-2
// AWS_USER_POOL_ID=us-east-2_kGCSGeupA
// AWS_CLIENT_ID=26db3oc54jvose3b20no56sftn
// AWS_CLIENT_SECRET=1iluh2ic9m7doi760h0scv0ccg13doce70o9dmfus4dgjfudhklg

// RRSS
// const awsConfig = {
//     region: 'us-west-2',
//     userPoolId: 'us-west-2_cLu8hnGYT',
//     clientId: '241g88g049r2hk4c8hun1n7usf',
//     clientSecret: '4jam5ia7g7po2vmmro5vk00pfhu82agk1t08n3pu7a9qkdgu3dg'
// }

//Personal
const awsConfig = {
    region: 'us-east-2',
    userPoolId: 'us-east-2_kGCSGeupA',
    clientId: '26db3oc54jvose3b20no56sftn',
    clientSecret: '1iluh2ic9m7doi760h0scv0ccg13doce70o9dmfus4dgjfudhklg'
}

config.update({ region: awsConfig.region })
const cognito = new CognitoIdentityServiceProvider()

export const login = async (username, password) => {
    const secretHash = generateSecretHash(username, awsConfig.clientId, awsConfig.clientSecret)

    try {
        const params = {
            AuthFlow: 'USER_PASSWORD_AUTH',
            ClientId: awsConfig.clientId,
            AuthParameters:{
                USERNAME: username,
                PASSWORD: password,
                SECRET_HASH: secretHash
            }
        }

        const data = await cognito.initiateAuth(params).promise()
        console.log(data)

        if( data.ChallengeName === 'NEW_PASSWORD_REQUIRED' ) {
            // console.log(url.AWS_CHANGE_PASSWORD_URL)
            console.log(data.ChallengeName)
            return { error: true, ...data, newUser: true }

        }

        const result = data.AuthenticationResult
        const queryParams = `#id_token=${result.IdToken}&access_token=${result.AccessToken}&expires_in=${result.ExpiresIn}&token_type=${result.TokenType}`
        // console.log(queryParams)
        // window.location = `https://app.hero.boston/${queryParams}`
        window.open(`https://app.hero.boston/${queryParams}`, '_parent')
        return { error: false, message: queryParams }


    } catch (err) {
        console.log(err.name, err.message)
    }
}

export const changePassword = async (username, password, session) => {
    const secretHash = generateSecretHash(username, awsConfig.clientId, awsConfig.clientSecret)

    const params = {
        ChallengeName: 'NEW_PASSWORD_REQUIRED',
        ClientId: awsConfig.clientId,
        ChallengeResponses: {
          NEW_PASSWORD: password,
          USERNAME: username,
          SECRET_HASH: secretHash,
        },
        Session: session
    }

    try {
        const response = await cognito.respondToAuthChallenge(params).promise()
        console.log('Contraseña Cambiada Exitosamente')

        const result = response.AuthenticationResult
        const queryParams = `#id_token=${result.IdToken}&access_token=${result.AccessToken}&expires_in=${result.ExpiresIn}&token_type=${result.TokenType}`
        window.location = `https://app.hero.boston/${queryParams}`

    } catch (err) {
        console.log('error')
        console.log(err.name, err.message)
    }
}