import { useState } from "react"
import LoginForm from "./LoginForm"
import ChangePasswordForm from "./ChangePasswordForm"
import { login } from "../../utils/cognito-funtions"
import '../../assets/css/divider.css'

export default function LoginSection() {
    const [isNewUser, setIsNewUser] = useState(false)
    const [authChallengeData, setAuthChallengeData] = useState([])


    const handleLogin = async values => {
        console.log(values)

        const response = await login(values.username, values.password)
        console.log(response)

        if( response.error ) {
            if( response.newUser ) {
                setIsNewUser(true)
                setAuthChallengeData(response)
            }

            if( response.message ) {
                console.log(response.message)
            }
        }
    }

    return (
        <div
            style={{
                maxWidth: '500px',
                backgroundColor: '#f4f5f5'
            }}
        >
            <h4
                style={{
                    textAlign: 'center'
                }}
            >{ isNewUser ? 'Debes Cambiar tu Contraseña' : 'Iniciar Sesión en Hero' }</h4>
            <div
                style={{
                    // maxWidth: '500px',
                    border: '1px  solid #000',
                    padding: '3px'
                }}
            >
                { isNewUser ? <ChangePasswordForm challengeData={authChallengeData} /> : <LoginForm handleLogin={handleLogin} /> }
            </div>
        </div>
    )
}