export default function MicrosoftButton() {
    return (
        <button
            type="button"
            className="btn"
            style={{
                backgroundColor: 'var(--zds-colors-neutral-700, #403f3e)'
            }}
        >
            <div className="content">
                <div className="icon">
                    <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
                        <title>Microsoft Logo</title>
                        <rect fill="#f25022" height="9" width="9" x="1" y="1"></rect>
                        <rect fill="#00a4ef" height="9" width="9" x="1" y="11"></rect>
                        <rect fill="#7fba00" height="9" width="9" x="11" y="1"></rect>
                        <rect fill="#ffb900" height="9" width="9" x="11" y="11"></rect>
                    </svg>
                </div>
            </div>
            Continue with Microsoft
        </button>
    )
}